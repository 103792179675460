<template>
  <div>
    <NavbarSection />
    

    <section class="py-36 md:h-screen h-auto items-center flex relative bg-bottom bg-cover" :style="{ backgroundImage: `url(${bgindexseven})` }" id="home">
        <div class="absolute inset-0 -z-1 bg-gradient-to-b from-orange-600/20 dark:from-orange-600/40 via-orange-600/10 dark:via-orange-600/20 to-transparent"></div>
        <div class="container relative">
            <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div class="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                    <h4 class="lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">Give your users <br> privacy choices.</h4>
            
                    <p class="text-slate-400 dark:text-white/70 mb-0 max-w-2xl text-lg">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                
                    <div class="subcribe-form mt-10">
                        <form class="relative">
                            <input type="email" id="subemail" name="email" class="rounded-full bg-white opacity-70 border" placeholder="E-mail :">
                            <button type="submit" class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full">Submit <i class="uil uil-arrow-right"></i></button>
                        </form><!--end form-->
                    </div>
                </div>

                <div class="lg:col-span-5 md:col-span-6">
                    <div class="relative">
                        <div class="relative flex justify-end">
                            <img src="@/assets/images/ab01.jpg" class="lg:w-[400px] w-[280px] rounded-xl shadow dark:shadow-gray-700" alt="">
                            <div class="absolute top-0 translate-y-2/4 start-0 text-center">
                                <a href="#!" data-type="youtube" data-id="S_CGed6E610" class="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-orange-600">
                                    <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </a>
                            </div>
                        </div>
                        <div class="absolute md:-start-5 start-0 -bottom-16">
                            <img src="@/assets/images/ab02.jpg" class="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-xl" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div><!--end container-->
    </section><!--end section-->

    <!-- End -->
    <AboutSection />
    <ServiceSection />
    <PortfolioSection />
    <TestimonialSection />
    <PricingSection />
    <TeamSection />
    <BlogSection />
    <ContactSection />
    <FooterSection />
    <SwitcherSection />
  </div>
</template>
  
<script>
import NavbarSection from '../components/NavbarSection.vue';
import AboutSection from '../components/AboutSection.vue';
import ServiceSection from '../components/ServiceSection.vue';
import PortfolioSection from '../components/PortfolioSection.vue';
import TeamSection from '../components/TeamSection.vue';
import PricingSection from '../components/PricingSection.vue';
import BlogSection from '../components/BlogSection.vue';
import ContactSection from '../components/ContactSection.vue';
import FooterSection from '../components/FooterSection.vue';
import TestimonialSection from '../components/TestimonialSection.vue';
import SwitcherSection from '../components/SwitcherSection.vue';
import bgindexseven from '../assets/images/bg/bg4.png';

export default {
  name: 'PageIndexSeven',
  components: {
    NavbarSection,
    AboutSection,
    ServiceSection,
    PortfolioSection,
    TeamSection,
    PricingSection,
    BlogSection,
    ContactSection,
    FooterSection,
    TestimonialSection,
    SwitcherSection
  },

    data() {
        return {
        bgindexseven
        }
    }
}
</script>
  