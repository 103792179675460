<template>
  <div>
    <NavbarSection />
     <!-- Hero Start -->
     <section class="py-36 lg:py-64 w-full table relative bg-center bg-cover" :style="{ backgroundImage: `url(${bgindexone})` }" id="home">
            <div class="absolute inset-0 bg-black opacity-80"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 mt-12">
                    <h4 class="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">Tailored Strategy and Resources <br>For Your <span class="typewrite relative text-type-element" ref="typedText" data-type=''></span>Program</h4>
                
                    <p class="text-white opacity-50 mb-0 max-w-2xl text-lg">Transform challenges into opportunities and capitalize on our industry-leading insights. Our expert team accompanies you at every phase of your technology journey, ensuring robust outcomes and maximizing ROI.</p>
                
                    <div class="relative mt-10">
                        <a href="#contact" class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">Get Started</a>
                    </div>
                </div>
            </div><!--end container-->
        </section><!--end section-->
        <!-- Hero End -->

    <AboutSection />
    <ServiceSection />
    <ContactSection />
    <FooterSection />
    <!-- <SwitcherSection /> -->
  </div>
</template>

<script>
import NavbarSection from '../components/NavbarSection.vue';
import AboutSection from '../components/AboutSection.vue';
import ServiceSection from '../components/ServiceSection.vue';
import FooterSection from '../components/FooterSection.vue';
// import SwitcherSection from '../components/SwitcherSection.vue';
import ContactSection from '../components/ContactSection.vue';
import Typed from 'typed.js';
import bgindexone from '../assets/images/bg/1.jpg';

export default {
  name: 'HomeIndex',
  components: {
    NavbarSection,
    AboutSection,
    ServiceSection,
    ContactSection,
    FooterSection,
    // SwitcherSection
  },
  data() {
    return {
      typed: null,
        bgindexone
    };
  },
  mounted() {
    this.setupTyped();
  },
  methods: {
    setupTyped() {
      const options = {
        strings: ["Generative AI", "Digital Transformation", "Telemedicine", "Cybersecurity", "FHIR Interoperability","Value-Based Care" ],
        typeSpeed: 50,  // Typing speed in milliseconds
        backSpeed: 30,  // Backspacing speed in milliseconds
        loop: true,     // Set to true to loop through strings
      };

      this.typed = new Typed(this.$refs.typedText, options);
    },
    ScrollIntoView(elem) {
            this.active = elem;
            let ele = document.querySelector(elem);
            ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
  },

}
</script>
