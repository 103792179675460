<template>
    <div>
        <section class="relative md:py-24 py-16" id="features">
            <div class="container md:mt-24 mt-16">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-orange-600 text-base font-medium uppercase mb-2">How can we help</h6>
                    <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Digital Transformation for Your Business</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Let's address your unique healthcare challenges together. Our tailored digital solutions deliver measurable improvements in efficiency, patient care, and your bottom line.</p>
                    
                </div><!--end grid-->

                <div class="grid grid-cols-1 mt-8">
                    <div class="timeline relative">
                        <div class="timeline-item">
                            <div class="grid sm:grid-cols-2">
                                <div class="">
                                    <div class="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                                        <img src="@/assets/images/svg/design-thinking.svg" class="h-64 w-64" alt="">
                                    </div>
                                </div><!--end col-->
                                <div class="mt-4 md:mt-0">
                                    <div
                                        class="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                                        <h5 class="text-lg dark:text-white mb-1 font-medium">Strategy</h5>
                                        <p class="timeline-subtitle mt-3 mb-0 text-slate-400">Combine healthcare industry knowledge with digital expertise to develop a tailored transformation plan for your organization. We support all types of initiatives: from cutting-edge Generative AI to traditional Value Chain Optimization.</p>
                                        
                                    </div>
                                </div><!--end col-->
                            </div><!--end grid-->
                        </div><!--end timeline item-->

                        <div class="timeline-item mt-5 pt-4">
                            <div class="grid sm:grid-cols-2">
                                <div class="md:order-1 order-2">
                                    <div
                                        class="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                                        <h5 class="text-lg dark:text-white mb-1 font-medium">Development</h5>
                                        <p class="timeline-subtitle mt-3 mb-0 text-slate-400">Create practical, compliant, and innovative solutions that seamlessly integrate into your existing healthcare ecosystem. We can help you build new HIPAA-compliant applications, optimize existing ones, or develop a program action plan.</p>
                                    </div>
                                </div><!--end col-->
                                <div class="md:order-2 order-1">
                                    <div class="duration duration-right md:ms-7 relative">
                                        <img src="@/assets/images/svg/coding.svg" class="h-64 w-64" alt="">
                                    </div>
                                </div><!--end col-->
                            </div><!--end grid-->
                        </div><!--end timeline item-->

                        <div class="timeline-item mt-5 pt-4">
                            <div class="grid sm:grid-cols-2">
                                <div class="mt-4 mt-sm-0">
                                    <div class="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                                        <img src="@/assets/images/svg/office-desk.svg" class="h-64 w-64" alt="">
                                    </div>
                                </div><!--end col-->
                                <div class="mt-4 mt-sm-0">
                                    <div
                                        class="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                                        <h5 class="text-lg dark:text-white mb-1 font-medium">Optimization</h5>
                                        <p class="timeline-subtitle mt-3 mb-0 text-slate-400">Refine and maximize the impact of your digital health initiatives. We work with your team to drive adoption of new technologies, redesign clinical workflows, and continuously improve based on real-world data and outcomes. This ongoing process ensures your digital transformation remains effective, compliant, and aligned with evolving healthcare needs and regulations</p>
                                    </div>
                            </div><!--end col-->
                        </div><!--end grid-->
                    </div><!--end timeline item-->
                </div>
            </div>
        </div><!--end container-->
    </section><!--end section-->
    <!-- End -->
</div></template>
  
<script>
export default {
    name: 'ServiceSection',
    data() {
        return {
            services: [
                {
                    logo: 'adjust-circle',
                    title: 'Grow Your Business',
                    desc: 'The phrasal sequence of the is now so that many campaign and benefit'
                },
                {
                    logo: 'circuit',
                    title: 'Drive More Sales',
                    desc: 'The phrasal sequence of the is now so that many campaign and benefit'
                },
                {
                    logo: 'fire',
                    title: 'Handled By Expert',
                    desc: 'The phrasal sequence of the is now so that many campaign and benefit'
                },
                {
                    logo: 'flower',
                    title: 'Discussion For Idea',
                    desc: 'The phrasal sequence of the is now so that many campaign and benefit'
                },
                {
                    logo: 'shopping-basket',
                    title: 'Increase Conversion',
                    desc: 'The phrasal sequence of the is now so that many campaign and benefit'
                },
                {
                    logo: 'flip-h',
                    title: 'Sales Growth Idea',
                    desc: 'The phrasal sequence of the is now so that many campaign and benefit'
                }
            ]

        }
    }
}
</script>
  