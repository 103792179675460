<template>
    <div>
      <NavbarSection />
      <section class="py-36 md:py-72 w-full table relative bg-center bg-cover jarallax" :style="{ backgroundImage: `url(${bgindexthree})` }" data-jarallax data-speed="0.5" id="home">
            <div class="absolute inset-0 bg-black opacity-70"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 text-center">
                    <h4 class="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">Do you want to change the world?</h4>
                
                    <p class="text-white opacity-50 mb-0 max-w-2xl text-lg mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                
                    <div class="text-center subcribe-form mt-4 pt-2">
                        <form class="relative mx-auto">
                            <input type="email" id="subemail" name="email" class="rounded-full bg-white opacity-70 border" placeholder="E-mail :">
                            <button type="submit" class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full">Submit <i class="uil uil-arrow-right"></i></button>
                        </form><!--end form-->
                    </div>
                </div>
            </div><!--end container-->
        </section><!--end section-->
  
      <AboutSection />
      <ServiceSection />
      <PortfolioSection />
      <TestimonialSection />
      <PricingSection />
      <TeamSection />
      <BlogSection />
      <ContactSection />
      <FooterSection />
      <SwitcherSection />
    </div>
  </template>
  
  <script>
  import NavbarSection from '../components/NavbarSection.vue';
  import AboutSection from '../components/AboutSection.vue';
  import ServiceSection from '../components/ServiceSection.vue';
  import PortfolioSection from '../components/PortfolioSection.vue';
  import TeamSection from '../components/TeamSection.vue';
  import PricingSection from '../components/PricingSection.vue';
  import BlogSection from '../components/BlogSection.vue';
  import ContactSection from '../components/ContactSection.vue';
  import FooterSection from '../components/FooterSection.vue';
  import TestimonialSection from '../components/TestimonialSection.vue';
  import SwitcherSection from '../components/SwitcherSection.vue';
  import bgindexthree from '../assets/images/bg/4.jpg';
  
  export default {
    name: 'PageIndexThree',
    components: {
      NavbarSection,
      AboutSection,
      ServiceSection,
      PortfolioSection,
      TeamSection,
      PricingSection,
      BlogSection,
      ContactSection,
      FooterSection,
      TestimonialSection,
      SwitcherSection
    },

    data() {
        return {
        bgindexthree
        }
    }
   
  }
  </script>
  