<template>
    <!-- Navbar Start -->
    <nav class="navbar" id="navbar">
        <div class="container flex flex-wrap items-center justify-end">
            <router-link class="navbar-brand" to="/index">
                <img src="@/assets/images/logo-dark2.png" class="inline-block dark:hidden" alt="">
                <img src="@/assets/images/logo-light2.png" class="hidden dark:inline-block" alt="">
            </router-link>

            <div class="nav-icons flex items-center lg_992:order-2 ms-auto">
                <!-- Navbar Button -->
                <!-- <ul class="list-none menu-social mb-0">
                    <li class="inline">
                        <a href="javascript:void(0);"
                            class="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"><i
                                class="uil uil-github"></i></a>
                    </li>
                    <li class="inline">
                        <a href="javascript:void(0);"
                            class="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"><i
                                class="uil uil-twitter"></i></a>
                    </li>
                    <li class="inline">
                        <a href="javascript:void(0);">
                            <a href="javascript:void(0);"
                                class="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"><i
                                    class="uil uil-instagram"></i></a>
                        </a>
                    </li>
                </ul> -->
                <!-- Navbar Collapse Manu Button -->
                <button data-collapse="menu-collapse" type="button" @click="toggleMenu()"
                    class="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                    aria-controls="menu-collapse" aria-expanded="false">
                    <span class="sr-only">Navigation Menu</span>
                    <i class="mdi mdi-menu mdi-24px"></i>
                </button>
            </div>

            <!-- Navbar Manu -->
            <div class="navigation lg_992:order-1 lg_992:flex hidden" id="menu-collapse">
                <ul class="navbar-nav" id="navbar-navlist" :class="{'nav-light': this.$route.path !== '/index-four' && this.$route.path !== '/index-five' && this.$route.path !== '/index-seven'}">
                    <li class="nav-item" :class="{ 'active': active == '#home' }">
                        <a class="nav-link" @click="ScrollIntoView('#home')" href="javascript:void(0);">Home</a>
                    </li>
                    <li class="nav-item" :class="{ 'active': active == '#about' }">
                        <a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#about')">Our Story</a>
                    </li>
                    <li class="nav-item" :class="{ 'active': active == '#features' }">
                        <a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#features')">Services</a>
                    </li>
                    <!-- <li class="nav-item" :class="{ 'active': active == '#portfolio' }">
                        <a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#portfolio')">Portfolio</a>
                    </li> -->
                    <!-- <li class="nav-item" :class="{ 'active': active == '#testi' }">
                        <a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#testi')">Review</a>
                    </li> -->
                    <!-- <li class="nav-item" :class="{ 'active': active == '#pricing' }">
                        <a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#pricing')">Pricing</a>
                    </li> -->
                    <!-- <li class="nav-item" :class="{ 'active': active == '#blog' }">
                        <a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#blog')">Blog</a>
                    </li> -->
                    <li class="nav-item" :class="{ 'active': active == '#contact' }">
                        <a class="nav-link" href="javascript:void(0);" @click="ScrollIntoView('#contact')">Contact us</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Navbar End -->
</template>
<script>

export default {
    name: 'NavbarSection',
    data() {
        return {
            active: '#home'
        }
    },
    created() {
        document.documentElement.classList.add('light');
        document.documentElement.setAttribute('dir', 'ltr');

        var bodyElement = document.body;
        bodyElement.classList.add('font-rubik', 'text-base', 'text-slate-900', 'dark:text-white', 'dark:bg-slate-900');

        window.addEventListener('scroll', (ev) => {
            ev.preventDefault();
            const navbar = document.getElementById("navbar");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                navbar.classList.add("is-sticky");
            } else {
                navbar.classList.remove("is-sticky");
            }


            var mybutton = document.getElementById("back-to-top");
            if (mybutton != null) {
                if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                    mybutton.classList.add("block");
                    mybutton.classList.remove("hidden");
                } else {
                    mybutton.classList.add("hidden");
                    mybutton.classList.remove("block");
                }
            }
        });
    },
    methods: {
        ScrollIntoView(elem) {
            this.active = elem;
            let ele = document.querySelector(elem);
            ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        toggleMenu() {
            let ele = document.getElementById('menu-collapse');
            ele.classList.toggle('hidden');
        }
    }
}

</script>