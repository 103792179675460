<template>
    <div>
        <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
            <div class="container mx-auto">
                <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                    <div class="lg:col-span-5">
                        <div class="relative">
                            <img src="@/assets/images/about.jpg" class="rounded-lg shadow-lg relative" alt="">
                            <!-- <div class="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                <a href="javascript:void(0);" @click="openVideoPopup" data-type="youtube"
                                    data-id="S_CGed6E610"
                                    class="h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-orange-600">
                                    <i class="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </a>
                            </div> -->
                        </div>
                    </div><!--end col-->

                    <div class="lg:col-span-7">
                        <div class="lg:ms-7">
                            <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Who We Are ?</h6>
                            <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Our Company Story</h3>

                            <p class="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">We are former healthcare executives and tech leaders who've walked in your shoes.
With decades of experience in top academic medical centers, we've faced the same challenges you're dealing with now. We've led digital transformations, implemented cutting-edge technologies, and navigated complex healthcare systems.
Now, we're here to help you do the same. Whether it's AI, Cybersecurity, Value-based care, Telemedicine, or Interoperability, we bring real-world expertise to your toughest problems.
At Dalos Partners, we don't just advise - we partner with you to shape the future of healthcare.
                            </p>

                            <div class="relative mt-10">
                                <a href="#contact"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">Let's Talk !</a>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
    </div>

    <!-- Video Popup -->
    <div v-if="videoPopupVisible" class="video-popup">
        <div class="popup-content">
            <span class="close-button" @click="closeVideoPopup">&times;</span>
            <iframe :src="videoUrl" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
    <!-- Video Popup -->
</template>

  
<script>
export default {
    name: 'AboutSection',
    components: {
    },
    data() {
        return {
            videoPopupVisible: false,
            videoUrl: 'https://www.youtube.com/embed/S_CGed6E610',
        };
    },
    methods: {
        openVideoPopup() {
            this.videoPopupVisible = true;
        },
        closeVideoPopup() {
            this.videoPopupVisible = false;
        },
    },
}
</script>
  
<style scoped>
/* Add your component-specific styles here */
.video-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.popup-content {
    position: relative;
    max-width: 800px;
    width: 90%;
    max-height: 80vh;
    overflow: hidden;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

iframe {
    width: 100%;
    height: 500px;
}
</style>
