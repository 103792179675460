<template>
    <div>
        <!-- Start -->
        <section class="relative md:py-24 py-16" id="blog">
            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Blogs</h6>
                    <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Latest News</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our
                        expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8">
                    <div class="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden" v-for="(item, index) in blogData" :key="index">
                        <img :src="item.image" alt="">

                        <div class="content p-6">
                            <router-link to="/blog-detail"
                                class="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">{{ item.title }}</router-link>
                            <p class="text-slate-400 mt-3">{{item.text}}</p>

                            <div class="mt-5">
                                <router-link to="/blog-detail"
                                    class="btn btn-link hover:text-orange-600 dark:hover:text-orange-600 after:bg-orange-600 dark:text-white transition duration-500">Read
                                    More <i class="uil uil-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>

                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

    </div>
</template>

<script>
export default {
    name: 'BlogSection',
    data() {
        return {
            blogData: [
                {
                    image: require('@/assets/images/blog/1.jpg'),
                    title: 'Building Your Corporate Identity from Upwind',
                    text: 'The phrasal sequence of the is now so that many campaign and benefit'
                },
                {
                    image: require('@/assets/images/blog/2.jpg'),
                    title: 'The Right Hand of Business IT World',
                    text: 'The phrasal sequence of the is now so that many campaign and benefit'
                },
                {
                    image: require('@/assets/images/blog/3.jpg'),
                    title: 'Building Your Corporate Identity from Upwind',
                    text: 'The phrasal sequence of the is now so that many campaign and benefit'
                }
            ]
        }
    }
}
</script>