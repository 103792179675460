<template>
    <div>
        <!-- Start Footer -->
        <footer class="py-8 bg-slate-800 dark:bg-gray-900">
            <div class="container">
                <div class="grid md:grid-cols-12 items-center">
                    <div class="md:col-span-3">
                        <a href="#" class="logo-footer">
                            <img src="@/assets/images/logo-light2.png" class="md:ms-0 mx-auto" alt="">
                        </a>
                    </div>

                    <div class="md:col-span-5 md:mt-0 mt-8">
                        <div class="text-center">
                            <p class="text-gray-400">© {{new Date().getFullYear()}}
                                Dalos Partners</p>
                        </div>
                    </div>

                    
            </div><!--end row-->
        </div><!--end container-->
    </footer><!--end footer-->
    <!-- End Footer -->

</div></template>

<script>
export default {
    name: 'FooterSection'
}
</script>