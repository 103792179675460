<template>
    <div>
        <!-- Start -->
        <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Contact us</h6>
                    <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Get In Touch !</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Let's address your unique healthcare challenges together.</p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                    <div class="lg:col-span-8">
                        <div class="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                        <template v-if="!showThankYou">
                            <form ref="form" @submit.prevent="sendEmail">
                                <div class="grid lg:grid-cols-12 lg:gap-6">
                                    <div class="lg:col-span-6 mb-5">
                                        <input name="name" id="name" type="text" class="form-input" placeholder="Name :">
                                    </div>

                                    <div class="lg:col-span-6 mb-5">
                                        <input name="email" id="email" type="email" class="form-input"
                                            placeholder="Email :">
                                    </div><!--end col-->
                                </div>

                                <div class="grid grid-cols-1">
                                    <div class="mb-5">
                                        <input name="subject" id="subject" class="form-input" placeholder="Subject :">
                                    </div>

                                    <div class="mb-5">
                                        <textarea name="comments" id="comments" class="form-input textarea h-28"
                                            placeholder="Message :"></textarea>
                                    </div>
                                </div>
                                <button type="submit" id="submit" name="send"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center">Send
                                    Message</button>
                            </form>
                        </template>
                        <template v-else>
                            <div class="text-center">
                  <h4 class="text-2xl font-medium text-orange-600 mb-4">Thank you for contacting Dalos Partners!</h4>
                  <p class="text-slate-400">We appreciate your message and will get back to you soon.</p>
                </div>
                        </template>
                        </div>
                    </div>

                    <div class="lg:col-span-4">
                        <div class="lg:ms-8">
                            <div class="flex">
                                <div class="icons text-center mx-auto">
                                    <i class="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                </div>

                                <div class="flex-1 ms-6">
                                    <h5 class="text-lg dark:text-white mb-2 font-medium">Phone</h5>
                                    <a href="tel:+152534-468-854" class="text-slate-400">+1(866) 509-7474</a>
                                </div>
                            </div>

                            <div class="flex mt-4">
                                <div class="icons text-center mx-auto">
                                    <i class="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                </div>

                                <div class="flex-1 ms-6">
                                    <h5 class="text-lg dark:text-white mb-2 font-medium">Email</h5>
                                    <a href="mailto:contact@example.com" class="text-slate-400">hello@dalospartners.com</a>
                                </div>
                            </div>

                            <!-- <div class="flex mt-4">
                                <div class="icons text-center mx-auto">
                                    <i class="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                </div>

                                <div class="flex-1 ms-6">
                                    <h5 class="text-lg dark:text-white mb-2 font-medium">Location</h5>
                                    <p class="text-slate-400 mb-2">C/54 Northwest Freeway, Suite 558, Houston, USA 485</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
name: 'ContactSection',
data() {
    return {
      formData: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      showThankYou: false
    }
  },
methods: {
    sendEmail() {
      emailjs
        .sendForm('service_f0r266j', 'template_6mssb8e', this.$refs.form, {
          publicKey: 'T_66xueBjm7sMVnap',
        }
    )
        .then(
          () => {
            console.log('SUCCESS!');
            this.showThankYouMessage();
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    },
    showThankYouMessage() {
      this.showThankYou = true;
      setTimeout(() => {
        this.showThankYou = false;
        this.resetForm();
      }, 30000); // 30 seconds
    },
    resetForm() {
      this.formData = {
        name: '',
        email: '',
        subject: '',
        message: ''
      };
    }
  },
};
</script>