import { createRouter, createWebHistory } from 'vue-router';
import HomeIndex from '../pages/HomeIndex';
import PageIndexTwo from '../pages/PageIndexTwo';
import PageIndexThree from '../pages/PageIndexThree';
import PageIndexFour from '../pages/PageIndexFour';
import PageIndexFive from '../pages/PageIndexFive';
import PageIndexSix from '../pages/PageIndexSix';
import PageIndexSeven from '../pages/PageIndexSeven';
import PageIndexEight from '../pages/PageIndexEight';

import PortfolioDetail from '../pages/PortfolioDetail'
import BlogDetail from '../pages/BlogDetail';

import PageLogin from '../pages/auth/PageLogin';
import PageSignup from '../pages/auth/PageSignup';
import PageResetPassword from '../pages/auth/PageResetPassword';

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'HomeIndex',
    component: HomeIndex,
  },
  {
    path: '/index-two',
    name: 'PageIndexTwo',
    component: PageIndexTwo,
  },
  {
    path: '/index-three',
    name: 'PageIndexThree',
    component: PageIndexThree,
  },
  {
    path: '/index-four',
    name: 'PageIndexFour',
    component: PageIndexFour,
  },
  {
    path: '/index-five',
    name: 'PageIndexFive',
    component: PageIndexFive,
  },
  {
    path: '/index-six',
    name: 'PageIndexSix',
    component: PageIndexSix,
  },
  {
    path: '/index-seven',
    name: 'PageIndexSeven',
    component: PageIndexSeven,
  },
  {
    path: '/index-eight',
    name: 'PageIndexEight',
    component: PageIndexEight,
  },
  {
    path: '/portfolio-detail',
    name: 'PortfolioDetail',
    component: PortfolioDetail,
  },
  {
    path: '/blog-detail',
    name: 'BlogDetail',
    component: BlogDetail,
  },
  {
    path: '/auth/login',
    name: 'PageLogin',
    component: PageLogin,
  },
  {
    path: '/auth/signup',
    name: 'PageSignup',
    component: PageSignup,
  },
  {
    path: '/auth/reset-password',
    name: 'PageResetPassword',
    component: PageResetPassword
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;