<template>
    <div>
        
        <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="testi">
            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Testimonial</h6>
                    <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Client's Review</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our
                        expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 mt-8 relative">
                    <div class="tiny-two-item">
                        <div class="tiny-slide">
                            <div
                                class="lg:flex p-6 lg:p-0 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                                <img class="w-24 h-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto"
                                    src="@/assets/images/client/01.jpg" alt="" width="384" height="512">
                                <div class="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                                    <p class="text-base text-slate-500 dark:text-slate-200"> " It seems that only fragments
                                        of the original text remain in the Lorem Ipsum texts used today. " </p>

                                    <div>
                                        <span class="text-orange-600 block mb-1">Thomas Israel</span>
                                        <span class="text-slate-400 text-sm dark:text-white/60 block">Staff Engineer,
                                            Algolia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tiny-slide">
                            <div
                                class="lg:flex p-6 lg:p-0 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                                <img class="w-24 h-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto"
                                    src="@/assets/images/client/02.jpg" alt="" width="384" height="512">
                                <div class="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                                    <p class="text-base text-slate-500 dark:text-slate-200"> " The most well-known dummy
                                        text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "
                                    </p>

                                    <div>
                                        <span class="text-orange-600 block mb-1">Carl Oliver</span>
                                        <span class="text-slate-400 text-sm dark:text-white/60 block">Staff Engineer,
                                            Algolia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tiny-slide">
                            <div
                                class="lg:flex p-6 lg:p-0 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                                <img class="w-24 h-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto"
                                    src="@/assets/images/client/03.jpg" alt="" width="384" height="512">
                                <div class="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                                    <p class="text-base text-slate-500 dark:text-slate-200"> " One disadvantage of Lorum
                                        Ipsum is that in Latin certain letters appear more frequently than others. " </p>

                                    <div>
                                        <span class="text-orange-600 block mb-1">Barbara McIntosh</span>
                                        <span class="text-slate-400 text-sm dark:text-white/60 block">Staff Engineer,
                                            Algolia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tiny-slide">
                            <div
                                class="lg:flex p-6 lg:p-0 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                                <img class="w-24 h-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto"
                                    src="@/assets/images/client/04.jpg" alt="" width="384" height="512">
                                <div class="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                                    <p class="text-base text-slate-500 dark:text-slate-200"> " Thus, Lorem Ipsum has only
                                        limited suitability as a visual filler for German texts. " </p>

                                    <div>
                                        <span class="text-orange-600 block mb-1">Jill Webb</span>
                                        <span class="text-slate-400 text-sm dark:text-white/60 block">Staff Engineer,
                                            Algolia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
    setup() {
        const slider = ref(null);
        const slides = [{ title: 'Slide 1', image: require('@/assets/images/client/01.jpg') }, { title: 'Slide 2', image: require('@/assets/images/client/02.jpg') }]; // Add your content here

        onMounted(() => {
            tns({
                container: '.tiny-two-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            768: {
                items: 2
            },
        },
            });
        });

        return { slider, slides };
    },
    name: 'TestimonialSection'
}
</script>

