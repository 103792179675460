<template>
    <div>
      <NavbarSection />
     <!-- Start Hero -->
     <section class="py-36 lg:py-72 lg:pb-0 md:pb-8 w-full table relative bg-center bg-cover" :style="{ backgroundImage: `url(${bgindextwo})` }" id="home">
            <div class="absolute inset-0 bg-black opacity-80"></div>
            <div class="container relative">
                <div class="grid lg:grid-cols-12 mt-10 md:grid-cols-2 gap-2">
                    <div class="lg:col-span-7">
                        <h4 class="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">Bluid your audiance <br> and sale more</h4>
                
                        <p class="text-white opacity-50 mb-0 max-w-2xl text-lg">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                    
                        <div class="relative mt-10">
                            <a href="javascript:void(0);" class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full me-1">Get Started</a>
                            <a href="#!" data-type="youtube" data-id="S_CGed6E610" class="btn btn-icon btn-lg bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full lightbox"><i class="mdi mdi-play text-xl align-middle"></i></a><small class="text-white text-sm uppercase align-middle ms-2">Watch Now</small>
                        </div>
                    </div>

                    <div class="lg:col-span-5 mt-8 md:mt-0">
                        <div class="bg-white dark:bg-slate-900 rounded-xl shadow md:p-10 p-6 lg:ms-10 z-10 relative">
                            <h4 class="mb-5 font-medium text-2xl dark:text-white">We are offering 14 <br> days free trial</h4>

                            <form>
                                <div class="grid grid-cols-1">
                                    <div class="mb-5">
                                        <label class="dark:text-white">Name</label>
                                        <input name="name" id="fName" type="text" class="form-input mt-2" placeholder="Name :">
                                    </div>
                                    <div class="mb-5">
                                        <label class="dark:text-white">Email</label>
                                        <input name="email" id="yEmail" type="email" class="form-input mt-2" placeholder="Email :">
                                    </div> 
                                    <div class="mb-5">
                                        <label class="dark:text-white">Phone No.</label>
                                        <input name="number" id="phNumber" class="form-input mt-2" placeholder="+12 12458 854">
                                    </div>
                                    <a href="javascript:void (0)" class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-full">Free Trial</a>
                                </div><!--end row-->
                            </form>
                            <!-- end form -->
                        </div>
                    </div>
                </div>
            </div><!--end container-->
        </section><!--end section-->
        <div class="relative">
            <div class="shape overflow-hidden text-gray-50 dark:text-slate-800 rtl:-scale-x-[1]">
                <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <!-- End Hero -->

  
      <AboutSection />
      <ServiceSection />
      <PortfolioSection />
      <TestimonialSection />
      <PricingSection />
      <TeamSection />
      <BlogSection />
      <ContactSection />
      <FooterSection />
      <SwitcherSection />
    </div>
  </template>
  
  <script>
  import NavbarSection from '../components/NavbarSection.vue';
  import AboutSection from '../components/AboutSection.vue';
  import ServiceSection from '../components/ServiceSection.vue';
  import PortfolioSection from '../components/PortfolioSection.vue';
  import TeamSection from '../components/TeamSection.vue';
  import PricingSection from '../components/PricingSection.vue';
  import BlogSection from '../components/BlogSection.vue';
  import ContactSection from '../components/ContactSection.vue';
  import FooterSection from '../components/FooterSection.vue';
  import TestimonialSection from '../components/TestimonialSection.vue';
  import SwitcherSection from '../components/SwitcherSection.vue';
  import bgindextwo from '../assets/images/bg/2.jpg';
  
  export default {
    name: 'PageIndexTwo',
    components: {
      NavbarSection,
      AboutSection,
      ServiceSection,
      PortfolioSection,
      TeamSection,
      PricingSection,
      BlogSection,
      ContactSection,
      FooterSection,
      TestimonialSection,
      SwitcherSection
    },

    data() {
        return {
        bgindextwo
        }
    }
   
  }
  </script>
  