<template>
    <div>
        <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="portfolio">
            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Portfolio</h6>
                    <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Our Works & Projects</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our
                        expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/1.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/2.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/3.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/4.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/5.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/6.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/7.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-sm overflow-hidden group">
                        <img src="@/assets/images/portfolio/8.jpg"
                            class="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                            alt="">
                        <div class="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                        <div class="content">
                            <div
                                class="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500">
                                <a href="javascript:void(0);"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon rounded-full lightbox"><i
                                        class="uil uil-camera"></i></a>
                            </div>

                            <div
                                class="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                                <router-link to="/portfolio-detail"
                                    class="h6 text-md font-medium text-white hover:text-orange-600 transition duration-500">Mockup
                                    Collection</router-link>
                                <p class="text-slate-100 tag mb-0">Abstract</p>
                            </div>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <!-- Start -->
        <section class="py-24 w-full table relative bg-center bg-cover" :style="{ backgroundImage: `url(${bgcta})` }">
            <div class="absolute inset-0 bg-black opacity-80"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-6 md:text-3xl text-2xl text-white font-medium">Ready to start your next web project now?
                    </h3>

                    <p class="text-white opacity-50 max-w-xl mx-auto">Launch your campaign and benefit from our expertise on
                        designing and managing conversion centered Tailwind CSS html page.</p>

                    <div class="relative mt-10">
                        <a href="javascript:void(0);"
                            class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">Get
                            Started !</a>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
    </div>
</template>

<script>

import bgcta from '../assets/images/bg/cta.png';

export default {
    name: 'PortfolioSection',

    data() {
        return {
        bgcta
        }
    }
}
</script>
  