<template>
    <div>
        <nav class="navbar" id="navbar">
            <div class="container flex flex-wrap items-center justify-end">
                <router-link class="navbar-brand" to="/index">
                    <span class="inline-block dark:hidden">
                        <img src="@/assets/images/logo-dark2.png" class="l-dark" alt="">
                        <img src="@/assets/images/logo-light2.png" class="l-light" alt="">
                    </span>
                    <img src="@/assets/images/logo-light2.png" class="hidden dark:inline-block" alt="">
                </router-link>

                <div class="nav-icons flex items-center lg_992:order-2 ms-auto">
                    <!-- Navbar Button -->
                    <ul class="list-none menu-social mb-0">
                        <li class="inline">
                            <a href="javascript:void(0);">
                                <span class="login-btn-primary"><span class="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"><i class="uil uil-github"></i></span></span>
                                <span class="login-btn-light"><span class="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><i class="uil uil-github"></i></span></span>
                            </a>
                        </li>
                        <li class="inline">
                            <a href="javascript:void(0);">
                                <span class="login-btn-primary"><span class="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"><i class="uil uil-twitter"></i></span></span>
                                <span class="login-btn-light"><span class="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><i class="uil uil-twitter"></i></span></span>
                            </a>
                        </li>
                        <li class="inline">
                            <a href="javascript:void(0);">
                                <span class="login-btn-primary"><span class="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white"><i class="uil uil-instagram"></i></span></span>
                                <span class="login-btn-light"><span class="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><i class="uil uil-instagram"></i></span></span>
                            </a>
                        </li>
                    </ul>
                    <!-- Navbar Collapse Manu Button -->
                    <button data-collapse="menu-collapse" @click="toggleMenu()" type="button" class="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden" aria-controls="menu-collapse" aria-expanded="false">
                        <span class="sr-only">Navigation Menu</span>
                        <i class="mdi mdi-menu mdi-24px"></i>
                    </button>
                </div>

                <!-- Navbar Manu -->
                <div class="navigation lg_992:order-1 lg_992:flex hidden" id="menu-collapse">
                    <ul class="navbar-nav nav-light" id="navbar-navlist">
                        <li class="nav-item">
                            <a class="nav-link active" href="#home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#about">About us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#features">Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#portfolio">Portfolio</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#testi">Review</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#pricing">Pricing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#blog">Blog</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">Contact us</a>
                        </li>                       
                    </ul>
                </div>
            </div>
        </nav>
        <!-- Navbar End -->
        <!-- Hero Start -->
        <section class="py-28 w-full table relative bg-top bg-no-repeat" :style="{ backgroundImage: `url(${bgportfolio})` }" id="home">
            <div class="absolute inset-0 bg-slate-950/80"></div>

            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 class="mb-3 text-3xl leading-normal font-medium text-white">New Trends in SEO</h3>

                    <ul class="list-none">
                        <li class="inline text-white/60 me-2"> <span class="text-white">Client :</span> Calvin Carlo</li>
                        <li class="inline text-white/60"> <span class="text-white">Date :</span> 23th May, 2022</li>
                    </ul>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- Hero End -->

        <section class="relative md:py-24 py-16">
            <div class="container">
                <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                    <div class="lg:col-span-5 md:col-span-6">
                        <div class="grid grid-cols-1 gap-[30px]">
                            <img src="@/assets/images/portfolio/1.jpg" class="rounded-md" alt="">
                            <img src="@/assets/images/portfolio/2.jpg" class="rounded-md" alt="">
                            <img src="@/assets/images/portfolio/3.jpg" class="rounded-md" alt="">
                        </div><!--end grid-->
                    </div><!--end col-->

                    <div class="lg:col-span-7 md:col-span-6">
                        <div class="sticky top-20">
                            <div class="grid lg:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div class="lg:col-span-12">
                                    <div class="work-details">
                                        <h4
                                            class="text-xl font-medium mb-3 border-b border-gray-100 dark:border-gray-700 pb-3">
                                            Project Detail :</h4>
                                        <p class="text-slate-400">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                            Suscipit totam atque dignissimos porro, exercitationem, neque alias ea aliquid
                                            quibusdam voluptates impedit maxime aut asperiores consequatur iste. Corporis
                                            fuga ducimus dignissimos. Lorem ipsum dolor sit amet, consectetur adipisicing
                                            elit. Adipisci non dolorem consequatur vitae hic.</p>
                                        <p class="text-slate-400 mt-2">Suscipit totam atque dignissimos porro,
                                            exercitationem, neque alias ea aliquid quibusdam voluptates impedit maxime aut
                                            asperiores consequatur iste. Corporis fuga ducimus dignissimos.</p>
                                    </div>
                                </div><!--end col-->

                                <div class="lg:col-span-7">
                                    <div class="bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 p-6 rounded-md">
                                        <h5
                                            class="text-lg font-medium border-b border-gray-100 dark:border-gray-700 pb-3 mb-3">
                                            Project Info :</h5>
                                        <dl class="grid grid-cols-12 mb-0">
                                            <dt class="md:col-span-4 col-span-5 mt-2">Client :</dt>
                                            <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">Calvin Carlo</dd>

                                            <dt class="md:col-span-4 col-span-5 mt-2">Category :</dt>
                                            <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">Web Design</dd>

                                            <dt class="md:col-span-4 col-span-5 mt-2">Date :</dt>
                                            <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">23rd July, 2023</dd>

                                            <dt class="md:col-span-4 col-span-5 mt-2">Website :</dt>
                                            <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">www.yourdomain.com</dd>

                                            <dt class="md:col-span-4 col-span-5 mt-2">Location :</dt>
                                            <dd class="md:col-span-8 col-span-7 mt-2 text-slate-400">3/2/64 Mongus Street,
                                                UK</dd>
                                        </dl>
                                    </div>
                                </div><!--end col-->
                            </div><!--end grid-->
                        </div>
                    </div><!--end col-->
                </div><!--end grid-->
            </div><!--end container-->

            <div class="container lg:mt-24 mt-16">
                <div class="grid grid-cols-1 text-center">
                    <h3 class="mb-6 md:text-2xl text-xl font-medium">Have Question ? Get in touch!</h3>

                    <p class="text-slate-400 max-w-xl mx-auto">This is just a simple text made for this unique and awesome
                        template, you can replace it with any text.</p>

                    <div class="mt-6">
                        <router-link to="#!"
                            class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"><i
                                class="uil uil-phone align-middle me-2"></i> Contact us</router-link>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
        <FooterSection />
        <SwitcherSection />
    </div>
</template>

<script>
import FooterSection from '../components/FooterSection.vue';
import SwitcherSection from '../components/SwitcherSection.vue';
import bgportfolio from '../assets/images/bg/auth.jpg';

export default {
    name: 'PortfolioDetail',
    components: {
        FooterSection,
        SwitcherSection
    },
    created() {
        document.documentElement.classList.add('light');
        document.documentElement.setAttribute('dir', 'ltr');
        var bodyElement = document.body;
        bodyElement.classList.add('font-rubik', 'text-base', 'text-slate-900', 'dark:text-white', 'dark:bg-slate-900');

        window.addEventListener('scroll', (ev) => {
            ev.preventDefault();
            const navbar = document.getElementById("navbar");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                navbar.classList.add("is-sticky");
            } else {
                navbar.classList.remove("is-sticky");
            }


            var mybutton = document.getElementById("back-to-top");
            if (mybutton != null) {
                if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                    mybutton.classList.add("block");
                    mybutton.classList.remove("hidden");
                } else {
                    mybutton.classList.add("hidden");
                    mybutton.classList.remove("block");
                }
            }
        });
    },
    methods: {
        ScrollIntoView(elem) {
            this.active = elem;
            let ele = document.querySelector(elem);
            ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        toggleMenu() {
            let ele = document.getElementById('menu-collapse');
            ele.classList.toggle('hidden');
        }
    },

    data() {
        return {
        bgportfolio
        }
    }
}
</script>