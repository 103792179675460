<template>
    <div>
      <NavbarSection />
       <!-- Start -->
       <section class="py-36 md:py-64 w-full table relative bg-orange-600/5 dark:bg-orange-600/10 bg-center bg-cover" :style="{ backgroundImage: `url(${bgindexfour})` }" id="home">
            <div class="container relative">
                <div class="grid grid-cols-1 mt-12">
                    <h4 class="lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">Business Growth <br> Makes Your Company</h4>
                
                    <p class="text-slate-400 dark:text-white/70 mb-0 max-w-2xl text-lg">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                
                    <div class="relative mt-10">
                        <a href="javascript:void(0);" class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full me-1">Get Started</a>
                        <a href="#!" data-type="youtube" data-id="S_CGed6E610" class="btn btn-icon btn-lg bg-orange-600/5 hover:bg-orange-600 border-orange-600/10 hover:border-orange-600 text-orange-600 hover:text-white rounded-full lightbox"><i class="mdi mdi-play text-xl align-middle"></i></a><small class="font-medium text-sm uppercase align-middle ms-2 dark:text-white/70">Watch Now</small>
                    </div>
                </div>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
      <AboutSection />
      <ServiceSection />
      <PortfolioSection />
      <TestimonialSection />
      <PricingSection />
      <TeamSection />
      <BlogSection />
      <ContactSection />
      <FooterSection />
      <SwitcherSection />
    </div>
  </template>
  
  <script>
  import NavbarSection from '../components/NavbarSection.vue';
  import AboutSection from '../components/AboutSection.vue';
  import ServiceSection from '../components/ServiceSection.vue';
  import PortfolioSection from '../components/PortfolioSection.vue';
  import TeamSection from '../components/TeamSection.vue';
  import PricingSection from '../components/PricingSection.vue';
  import BlogSection from '../components/BlogSection.vue';
  import ContactSection from '../components/ContactSection.vue';
  import FooterSection from '../components/FooterSection.vue';
  import TestimonialSection from '../components/TestimonialSection.vue';
  import SwitcherSection from '../components/SwitcherSection.vue';
  import bgindexfour from '../assets/images/bg/01.png';
  
  export default {
    name: 'PageIndexFour',
    components: {
      NavbarSection,
      AboutSection,
      ServiceSection,
      PortfolioSection,
      TeamSection,
      PricingSection,
      BlogSection,
      ContactSection,
      FooterSection,
      TestimonialSection,
      SwitcherSection
    },

    data() {
        return {
        bgindexfour
        }
    }
   
  }
  </script>
  