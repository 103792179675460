<template>
    <div>
        <!-- Start -->
        <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="team">
            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h6 class="text-orange-600 text-base font-medium uppercase mb-2">Our Team</h6>
                    <h3 class="mb-4 md:text-2xl text-xl font-medium dark:text-white">Creative Minds</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our
                        expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-6 mt-8">
                    <div class="relative rounded-md shadow-lg overflow-hidden group">
                        <img src="@/assets/images/client/01.jpg"
                            class="group-hover:origin-center group-hover:scale-105 transition duration-500" alt="">
                        <div class="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 z-0 transition duration-500">
                        </div>
                        <ul
                            class="list-none absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 mb-0 transition-all duration-500">
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-facebook-f"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-instagram"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-twitter"></i></a></li>
                        </ul><!--end icon-->

                        <div
                            class="content absolute start-4 end-4 bottom-4 bg-white dark:bg-slate-900 opacity-0 group-hover:opacity-100 p-4 rounded-md text-center transition-all duration-500">
                            <a href="javascript:void(0);"
                                class="h5 text-md dark:text-white hover:text-orange-600 dark:hover:text-orange-600 font-medium">Calvin
                                Carlo</a>
                            <h6 class="text-slate-400 dark:text-slate-300 mb-0 font-light">Designer</h6>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-lg overflow-hidden group">
                        <img src="@/assets/images/client/02.jpg"
                            class="group-hover:origin-center group-hover:scale-105 transition duration-500" alt="">
                        <div class="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 z-0 transition duration-500">
                        </div>
                        <ul
                            class="list-none absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 mb-0 transition-all duration-500">
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-facebook-f"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-instagram"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-twitter"></i></a></li>
                        </ul><!--end icon-->

                        <div
                            class="content absolute start-4 end-4 bottom-4 bg-white dark:bg-slate-900 opacity-0 group-hover:opacity-100 p-4 rounded-md text-center transition-all duration-500">
                            <a href="javascript:void(0);"
                                class="h5 text-md dark:text-white hover:text-orange-600 dark:hover:text-orange-600 font-medium">Aliana
                                Rosy</a>
                            <h6 class="text-slate-400 dark:text-slate-300 mb-0 font-light">Designer</h6>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-lg overflow-hidden group">
                        <img src="@/assets/images/client/03.jpg"
                            class="group-hover:origin-center group-hover:scale-105 transition duration-500" alt="">
                        <div class="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 z-0 transition duration-500">
                        </div>
                        <ul
                            class="list-none absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 mb-0 transition-all duration-500">
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-facebook-f"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-instagram"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-twitter"></i></a></li>
                        </ul><!--end icon-->

                        <div
                            class="content absolute start-4 end-4 bottom-4 bg-white dark:bg-slate-900 opacity-0 group-hover:opacity-100 p-4 rounded-md text-center transition-all duration-500">
                            <a href="javascript:void(0);"
                                class="h5 text-md dark:text-white hover:text-orange-600 dark:hover:text-orange-600 font-medium">Sofia
                                Razaq</a>
                            <h6 class="text-slate-400 dark:text-slate-300 mb-0 font-light">Designer</h6>
                        </div>
                    </div>

                    <div class="relative rounded-md shadow-lg overflow-hidden group">
                        <img src="@/assets/images/client/04.jpg"
                            class="group-hover:origin-center group-hover:scale-105 transition duration-500" alt="">
                        <div class="absolute inset-0 bg-black opacity-0 group-hover:opacity-30 z-0 transition duration-500">
                        </div>
                        <ul
                            class="list-none absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 mb-0 transition-all duration-500">
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-facebook-f"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-instagram"></i></a></li>
                            <li class="mb-1 ms-0"><a href="javascript:void(0)"
                                    class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white btn-icon btn-sm rounded-full"><i
                                        class="uil uil-twitter"></i></a></li>
                        </ul><!--end icon-->

                        <div
                            class="content absolute start-4 end-4 bottom-4 bg-white dark:bg-slate-900 opacity-0 group-hover:opacity-100 p-4 rounded-md text-center transition-all duration-500">
                            <a href="javascript:void(0);"
                                class="h5 text-md dark:text-white hover:text-orange-600 dark:hover:text-orange-600 font-medium">Micheal
                                Carlo</a>
                            <h6 class="text-slate-400 dark:text-slate-300 mb-0 font-light">Designer</h6>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->

            <div class="container mt-12">
                <div class="grid grid-cols-2 md:grid-cols-4">
                    <div class="counter-box position-relative text-center">
                        <h3 class="font-medium text-3xl mb-2 dark:text-white">
                            <CountTo class="counter-value" :startVal="0" :endVal="40" :duration="5000" />+
                        </h3>
                        <span class="counter-head text-slate-400 dark:text-slate-300">Projects</span>
                    </div><!--end counter box-->

                    <div class="counter-box position-relative text-center">
                        <h3 class="font-medium text-3xl mb-2 dark:text-white">
                            <CountTo class="counter-value" :startVal="0" :endVal="200" :duration="5000" />+
                        </h3>
                        <span class="counter-head text-slate-400 dark:text-slate-300">Clients</span>
                    </div><!--end counter box-->

                    <div class="counter-box position-relative text-center">
                        <h3 class="font-medium text-3xl mb-2 dark:text-white">
                            <CountTo class="counter-value" :startVal="200" :endVal="457" :duration="5000" />K
                        </h3>
                        <span class="counter-head text-slate-400 dark:text-slate-300">Members</span>
                    </div><!--end counter box-->

                    <div class="counter-box position-relative text-center">
                        <h3 class="font-medium text-3xl mb-2 dark:text-white">
                            <CountTo class="counter-value" :startVal="100" :endVal="150" :duration="5000" />+
                        </h3>
                        <span class="counter-head text-slate-400 dark:text-slate-300">Employee</span>
                    </div><!--end counter box-->
                </div><!--end grid-->
            </div><!--end container-->

            <div class="container mt-12">
                <div class="grid md:grid-cols-6 grid-cols-2 justify-center gap-8">
                    <div class="mx-auto">
                        <img src="@/assets/images/client/amazon.svg" class="h-[25px]" alt="">
                    </div><!--end col-->

                    <div class="mx-auto">
                        <img src="@/assets/images/client/google.svg" class="h-[25px]" alt="">
                    </div><!--end col-->

                    <div class="mx-auto">
                        <img src="@/assets/images/client/lenovo.svg" class="h-[25px]" alt="">
                    </div><!--end col-->

                    <div class="mx-auto">
                        <img src="@/assets/images/client/paypal.svg" class="h-[25px]" alt="">
                    </div><!--end col-->

                    <div class="mx-auto">
                        <img src="@/assets/images/client/shopify.svg" class="h-[25px]" alt="">
                    </div><!--end col-->

                    <div class="mx-auto">
                        <img src="@/assets/images/client/spotify.svg" class="h-[25px]" alt="">
                    </div><!--end col-->
                </div><!--end grids-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
    </div>
</template>

<script>
import { CountTo } from 'vue3-count-to';
export default {
    name: 'TeamSection',
    components: {
        CountTo,
    },
}
</script>