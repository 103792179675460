<template>
  <div>
    <NavbarSection />
    <!-- Hero Start -->
    <section
      class="py-36 lg:py-64 w-full table relative bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${bgindexeight})` }"
      id="home">
      <div class="container relative z-1">
        <div class="grid grid-cols-1 mt-12">
          <h4 class="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
            Everything you need to build <br> a great company</h4>

          <p class="text-white opacity-50 mb-0 max-w-2xl text-lg">Launch your campaign and benefit from our expertise on
            designing and managing conversion centered Tailwind CSS html page.</p>

          <div class="relative mt-10">
            <a href="javascript:void(0);"
              class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">Get
              Started</a>
          </div>
        </div>
      </div><!--end container-->
      <div
        class="absolute lg:w-1/2 md:w-4/6 w-full h-full bg-gradient-to-t to-orange-600 from-orange-400 md:opacity-100 opacity-80 top-0">
      </div>
    </section><!--end section-->
    <!-- Hero End -->
    <AboutSection />
    <ServiceSection />
    <PortfolioSection />
    <TestimonialSection />
    <PricingSection />
    <TeamSection />
    <BlogSection />
    <ContactSection />
    <FooterSection />
    <SwitcherSection />
  </div>
</template>
  
<script>
import NavbarSection from '../components/NavbarSection.vue';
import AboutSection from '../components/AboutSection.vue';
import ServiceSection from '../components/ServiceSection.vue';
import PortfolioSection from '../components/PortfolioSection.vue';
import TeamSection from '../components/TeamSection.vue';
import PricingSection from '../components/PricingSection.vue';
import BlogSection from '../components/BlogSection.vue';
import ContactSection from '../components/ContactSection.vue';
import FooterSection from '../components/FooterSection.vue';
import TestimonialSection from '../components/TestimonialSection.vue';
import SwitcherSection from '../components/SwitcherSection.vue';
import bgindexeight from '../assets/images/bg/5.jpg';

export default {
  name: 'PageIndexEight',
  components: {
    NavbarSection,
    AboutSection,
    ServiceSection,
    PortfolioSection,
    TeamSection,
    PricingSection,
    BlogSection,
    ContactSection,
    FooterSection,
    TestimonialSection,
    SwitcherSection
  },

    data() {
        return {
        bgindexeight
        }
    }

}
</script>
  