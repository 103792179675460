import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import './assets/css/tailwind.css';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import './assets/libs/@iconscout/unicons/css/line.css';
import './assets/libs/tiny-slider/tiny-slider.css';

const app = createApp(App);

app.use(router);

app.mount('#app');

