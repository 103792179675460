<template>
    <div>
      <NavbarSection />
      <section class="py-36 lg:py-64 w-full table relative bg-center bg-cover" :style="{ backgroundImage: `url(${bgindexsix})` }" id="home">
            <div class="bg-video-wrapper">
                <iframe src="https://player.vimeo.com/video/405282090?background=1&autoplay=1&loop=1&byline=0&title=0"></iframe>
                <!--Note: Vimeo Embed Background Video-->

                <!-- <iframe src="https://www.youtube.com/embed/S_CGed6E610?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"></iframe> -->
                <!--Note: Youtube Embed Background Video-->
            </div>
            <div class="absolute inset-0 bg-black opacity-80"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 text-center">
                    <h4 class="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">We Collaboration Easy & fast</h4>
                
                    <p class="text-white opacity-50 mb-0 max-w-2xl text-lg mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>
                
                    <div class="relative mt-10">
                        <a href="javascript:void(0);" class="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md">Get Started</a>
                    </div>
                </div>
            </div><!--end container-->
        </section><!--end section-->

        <!-- End -->
      <AboutSection />
      <ServiceSection />
      <PortfolioSection />
      <TestimonialSection />
      <PricingSection />
      <TeamSection />
      <BlogSection />
      <ContactSection />
      <FooterSection />
      <SwitcherSection />
    </div>
  </template>
  
  <script>
  import NavbarSection from '../components/NavbarSection.vue';
  import AboutSection from '../components/AboutSection.vue';
  import ServiceSection from '../components/ServiceSection.vue';
  import PortfolioSection from '../components/PortfolioSection.vue';
  import TeamSection from '../components/TeamSection.vue';
  import PricingSection from '../components/PricingSection.vue';
  import BlogSection from '../components/BlogSection.vue';
  import ContactSection from '../components/ContactSection.vue';
  import FooterSection from '../components/FooterSection.vue';
  import TestimonialSection from '../components/TestimonialSection.vue';
  import SwitcherSection from '../components/SwitcherSection.vue';
  import bgindexsix from '../assets/images/bg/video.jpg';
  
  export default {
    name: 'PageIndexSix',
    components: {
      NavbarSection,
      AboutSection,
      ServiceSection,
      PortfolioSection,
      TeamSection,
      PricingSection,
      BlogSection,
      ContactSection,
      FooterSection,
      TestimonialSection,
      SwitcherSection
    },

    data() {
        return {
        bgindexsix
        }
    }
   
  }
  </script>
  